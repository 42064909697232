<template>
  <div class="biz-container">
    <div class="ant-modal-root order-detail_loader" v-if="loading">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin></a-spin>
        </div>
      </div>
    </div>
    <div v-if="!firstLoading">
      <ModalAddProduct
        :sellerName="detailDistributor && detailDistributor.name"
        :sellerId="detailDistributor && detailDistributor.seller_id"
        :showModal="showModalAddProduct"
        :warehouseId="warehouseId"
        @emitShowModal="emitShowModalAddProduct"
        @emitSubmitModal="emitSubmitModalAddProduct"
      />
      <ModalDetailProduct
        :productDetail="productDetail"
        :showModal="showModalDetailProduct"
        :propsIsEdit="propsIsEdit"
        :returId="returId"
        :warehouseId="warehouseId"
        @emitShowModal="emitShowModalDetailProduct"
        @emitSubmitModal="emitSubmitModalDetailProduct"
        @emitHandleLoading="emitHandleLoading"
      />
      <ModalRequestRetur
        :sellerName="detailDistributor && detailDistributor.name"
        :sellerId="detailDistributor && detailDistributor.seller_id"
        :showModal="showModalRequestRetur"
        :totalAmountReturRequested="totalAmountReturRequested"
        :productCount="listProductRetur && listProductRetur.length"
        :returId="returId"
        @emitShowModal="emitShowModalRequestRetur"
        @emitSubmitModal="emitSubmitModalRequestRetur"
      />
      <ModalConfirmLeave
        :showModal="showModalConfirmLeave || showModalConfirmCancel"
        :isCancel="showModalConfirmCancel"
        @emitShowModal="emitShowModalConfirmLeave"
        @emitSubmitModal="emitSubmitModalLeave"
      />
      <b-modal :no-close-on-backdrop="true" id="modal-response" v-model="showModalDelete" centered class="p-3" hide-footer :hide-header="true" size="400">
        <template v-if="!loading">
          <div class="pt-3">
            <div class="ml-2 d-flex flex-column modal-desc-container" style="gap: 3px 0px;">
              <p>Anda yakin ingin menghapus produk <b>{{ selectedDeleteData && (selectedDeleteData.catalogTitle !== null ? selectedDeleteData.catalogTitle : 'yang dipilih') }}</b> dari daftar permohonan retur?</p>
            </div>
            <a-row :gutter="[16, 16]">
              <a-col :xs="24" :sm="24" :md="12">
                <a-button
                  class="mt-3"
                  style="
                    width: 100%;
                    color: var(--biz-brand-button) !important;
                    border: 1px solid var(--biz-brand-button) !important;
                    border-radius: 12px !important;
                  "
                  @click.prevent="showModalDelete = false, selectedDeleteData = null"
                >
                  Batal
                </a-button>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12">
                <a-button
                  @click.prevent="() => {
                    if (selectedDeleteData.returItemId == null) {
                      onDeleteReturItemChecked()
                    } else {
                      onDeleteReturItem(selectedDeleteData && selectedDeleteData.returItemId)
                    }
                  }"
                  type="primary"
                  block
                  class="mt-3"
                  style="
                    width: 100%;
                    background: var(--biz-brand-button) !important;
                    color: #FFFFFF !important;
                    border: 1px solid var(--biz-brand-button) !important;
                    border-radius: 12px !important;
                  "
                >
                  Ya, Hapus
                </a-button>
              </a-col>
            </a-row>
          </div>
        </template>
        <template v-else>
          <div class="d-flex flex-column align-items-center justify-content-center py-3">
            <BigLoader class="rotate-img mb-4"/>
            <div class="loader-text-desc">Proses menghapus produk dari permohonan retur</div>
          </div>
        </template>
      </b-modal>

      <div class="d-flex justify-content-start align-items-center mb-3">
        <div>
          <a-icon @click.prevent="handleLeave" type="arrow-left"
            style="font-size: 20px; color: #000000; cursor: pointer" />
        </div>
        <div class="ml-3">
          <h2> {{ $t('menu.retur.list.return_request') }}</h2>
        </div>
      </div>
      <div class="px-5">
        <div class="d-flex justify-content-between mb-3">
          <div class="sub-title">
            Produk Yang Diretur
          </div>
          <div class="d-flex align-self-end align-items-center">
            <WarehousePinIcon color="var(--biz-secondary-100)"/>
            <div class="warehouse-name ml-2">{{ detailDistributor && detailDistributor.name }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-3 mt-5" style="align-items: center">
          <div v-if="listProductRetur.length > 0 ">
            <a-checkbox @change="onCheckAllChange" :checked="checkedAll" style="padding-left: 25px">
              <span class="checked-all">Pilih Sekaligus</span>
            </a-checkbox>
          </div>
          <div v-if="listProductRetur.length > 0 " class="d-flex justify-content-between" style="gap: 10px">
            <div class="delete-btn" @click.stop="onClickDeleteAction(null, null)" v-if="checkedList.length > 0">
              <a-icon type="delete" />
              {{ $t('purchase.cart.delete') }}
            </div>
            <a-button
              style="
                color: var(--biz-brand-button) !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;
              "
              @click.prevent="emitShowModalAddProduct(true)"
            >
              + Tambah Produk
            </a-button>
          </div>
        </div>
        <div style="overflow-x: auto;">
          <table style="width:100%; min-width: 1000px;">
            <tr class="text-left table-header" style="border-radius: 10px;">
              <th width="70"></th>
              <th> No </th>
              <th> Nama Produk </th>
              <th> Referensi No. Order </th>
              <th> Qty </th>
              <th> Harga Retur </th>
              <th width="100"> </th>
            </tr>
            <template v-if="listProductRetur.length > 0">
              <tr class="text-left tr-product-list table-body" v-for="(obj, index) in listProductRetur" :key="index">
                <td width="70" class="" style="text-align: center">
                  <a-checkbox @change="onCheckChange(obj.id)" :checked="checkedList.includes(obj.id)"/>
                </td>
                <td class="">{{ index+1 }}</td>
                <td width="400">
                  <div class="d-flex align-items-center py-3">
                    <img
                      alt="image-product"
                      :src="obj && obj.img_url"
                      style="width: 50px; height: 50px; object-fit: cover;"
                    />
                    <div class="ml-2 w-100">{{ obj && obj.catalog_title}}</div>
                  </div>
                </td>
                <td width="200" class="">{{ obj && obj.order_number}}</td>
                <td width="120" class="">{{ obj.requested_item_qty }} {{ obj.item_uom }}</td>
                <td class="">{{ `Rp. ${toCurrency(obj && obj.return_price)}` }}</td>
                <td class="">
                  <div class="d-flex align-items-center" style="gap: 0px 15px;">
                    <a-tooltip>
                      <template #title>Detail Produk</template>
                      <div @click.prevent="() => onClickDetailAction(obj)" class="d-flex align-items-center" style="cursor: pointer;">
                        <ActionViewIcon :width="'20'" :height="'20'" color="var(--biz-brand-button)"/>
                      </div>
                    </a-tooltip>
                    <a-tooltip>
                      <template #title>Ubah Qty</template>
                      <div @click.prevent="() => onClickEditAction(obj)" class="d-flex align-items-center" style="cursor: pointer;">
                        <ActionEditIcon :width="'20'" :height="'20'" color="var(--biz-brand-button)"/>
                      </div>
                    </a-tooltip>
                    <a-tooltip>
                      <template #title>Hapus Produk</template>
                      <div @click.prevent="() => onClickDeleteAction(obj && obj.id, obj && obj.catalog_title )" class="d-flex align-items-center" style="cursor: pointer;">
                        <ActionDeleteIcon :width="'20'" :height="'20'" color="var(--biz-brand-button)"/>
                      </div>
                    </a-tooltip>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </div>
        <div class="d-flex justify-content-end mb-3 mt-3 action-return-create" v-if="listProductRetur.length > 0">
          <a-button
            style="
              color: var(--biz-brand-button) !important;
              border: 1px solid var(--biz-brand-button) !important;
              border-radius: 12px !important;
            "
            @click.prevent="handleLeave(false)"
          >
            Batalkan
          </a-button>
          <a-button
            type="primary"
            class="w-fit"
            style="
              background: var(--biz-brand-button) !important;
              color: #FFFFFF !important;
              border: 1px solid var(--biz-brand-button) !important;
              border-radius: 12px !important;
            "
            @click.prevent="() => handleRequestRetur()"
          >
            Buat Tiket Retur
          </a-button>
        </div>
        <template v-if="listProductRetur.length === 0">
          <div class="d-flex pr-0 justify-content-center align-items-center flex-column pt-4">
            <div class="ml-2 align-items-center d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <EmptyIcon width="200" height="200"/>
              </div>
              <span class="ml-2 desc-empty-state">
                Silahkan tambah produk yang ingin di retur.
              </span>
              <div class="card-footer border-top-0 mt-1 w-100">
                <div
                  class="btn w-100 rounded align-self-end align-items-center"
                  @click.prevent="emitShowModalAddProduct(true)"
                  style="
                    background: var(--biz-brand-button) !important;
                    color: var(--biz-brand-base) !important;
                    border: 1px solid var(--biz-brand-button) !important;
                    border-radius: 12px !important;
                  "
                >
                  <span class="ml-2">+ Tambah Produk</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <div v-else id="wrap" class="col pr-0">
      data hasil fetch
    </div> -->
  </div>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import EmptyIcon from '@/components/Icons/Empty.vue'
import TablesAntdRowSelection from '@/views/tables/antd/examples/row-selection/index'
import WarehousePinIcon from '@/components/Icons/WarehousePin.vue'
import ActionViewIcon from '@/components/Icons/ActionView.vue'
import ActionEditIcon from '@/components/Icons/ActionEdit.vue'
import ActionDeleteIcon from '@/components/Icons/ActionDelete.vue'
import ModalAddProduct from '@/views/account/retur/create-retur/modal-add-product.vue'
import ModalDetailProduct from '@/views/account/retur/create-retur/modal-detail-product.vue'
import ModalRequestRetur from '@/views/account/retur/create-retur/modal-request-retur.vue'
import ModalConfirmLeave from '@/views/account/retur/create-retur/modal-confirm-leave.vue'
import BigLoader from '@/components/Icons/BigLoader.vue'

export default {
  name: 'component-purchase-index',
  components: {
    EmptyIcon,
    TablesAntdRowSelection,
    WarehousePinIcon,
    ActionViewIcon,
    ActionEditIcon,
    ActionDeleteIcon,
    ModalAddProduct,
    ModalDetailProduct,
    ModalRequestRetur,
    ModalConfirmLeave,
    BigLoader,
  },
  data: function () {
    return {
      loading: false,
      data: [],
      activeKey: 0,
      detailDistributor: null,
      listProductRetur: [],
      showModalAddProduct: false,
      showModalDetailProduct: false,
      showModalRequestRetur: false,
      showModalDelete: false,
      selectedDeleteData: null,
      totalAmountReturRequested: 0,
      returId: '',
      productDetail: null,
      propsIsEdit: false,
      showModalConfirmLeave: false,
      showModalConfirmCancel: false,
      warehouseId: null,
      checkedAll: false,
      checkedList: [],
      firstLoading: true,
    }
  },
  computed: {
    ...mapState(['purchase', 'apps',]),
  },
  methods: {
    handleRequestRetur() {
      this.$store.dispatch('loyalty/GETLOYALTY', {})
      this.showModalRequestRetur = true
    },
    emitHandleLoading(val) {
      this.loading = val
    },
    onCheckAllChange() {
      if (this.checkedAll == true) {
        this.checkedList = []
      } else {
        this.checkedList = this.listProductRetur.map((item) => item.id)
      }
      this.checkedAll = !this.checkedAll
    },
    onCheckChange(returitemId) {
      let checkedList = this.checkedList

      const index = checkedList.indexOf(returitemId);
      if (index > -1) {
        checkedList.splice(index, 1);
      } else {
        checkedList.push(returitemId)
      }

      if (checkedList.length == this.listProductRetur.length) {
        this.checkedAll = true
      } else {
        this.checkedAll = false
      }
      this.checkedList = checkedList
    },
    handleLeave(isBack = true) {
      if (this.listProductRetur.length > 0) {
        if (isBack) {
          this.showModalConfirmLeave = true
        } else {
          this.showModalConfirmCancel = true
        }
      } else {
        this.$router.push({ path: '/account/retur' })
      }
    },
    emitShowModalConfirmLeave(val) {
      this.showModalConfirmLeave = val
      this.showModalConfirmCancel = val
    },
    emitSubmitModalLeave(val) {
      if (val) {
        if (!this.showModalConfirmCancel) {
          this.$router.push({ path: '/account/retur' })
        } else {
          this.$store.dispatch('retur/ONDELETERETUR', {
            channel_id: this.$store.state.user.user_data.channel_id,
            return_id: this.returId,
          })
            .then(() => {
              this.$router.push({ path: '/account/retur' })
            })
        }
      }
    },
    getDraftRetur(status) {
      this.loading = true
      this.$store.dispatch('retur/GETRETUR', {
        status,
        page: 1,
        limit: 1,  
        channel_id: this.$store.state.user.user_data.channel_id,
        buyer_id: this.$store.state.user.user_data.buyer_id,
      })
        .then(({ data }) => {
          this.firstLoading = false
          if (data?.total_elements > 0) {
            const selectedReturDraft = data?.contents?.length > 0 ? data?.contents[0] : null
            if (selectedReturDraft) {
              this.getDraftReturItems(selectedReturDraft.return_number)
            } else {
              this.$notification.error({
                message: 'retur number not found',
              })
            }
          } else {
            this.listProductRetur = []
            this.fetchDetailDistributor()
          }
          this.loading = false
        })
    },
    getWarehouseIdAndFetchBaseQty(seller_id, arrReturnItems) {
      this.loading = true
      this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
        channel_id: this.$store.state.user.user_data.channel_id,
        seller_id: [seller_id],
      })
      .then(({ data }) => {
        if (data.length > 0) {
          this.warehouseId = data[0].warehouse_id
          const arrCatalogId = arrReturnItems?.map((obj) => obj.catalog_id)
          const uniqueCatalogId = [...new Set(arrCatalogId)]
          this.getBaseQtyProduct(uniqueCatalogId, data[0].warehouse_id)
        }
        this.loading = false
      })
    },
    getDraftReturItems(returNumber) {
      this.loading = true
      this.$store.dispatch('retur/GETRETURDETAIL', {
        channel_id: this.$store.state.user.user_data.channel_id,
        retur_number: returNumber,
      })
      .then(({ data }) => {
        this.getWarehouseIdAndFetchBaseQty(data.seller_id, data?.return_items)
        if (data?.return_items?.length > 0) {
          this.listProductRetur = data?.return_items
          this.totalAmountReturRequested = data?.total_amount_requested
          this.returId = data.id

          this.detailDistributor = {
            seller_id: data.seller_id,
            name: data.seller_name,
          }

          const arrProductId = data?.return_items?.map((obj) => obj.product_id)
          const uniqueProductId = [...new Set(arrProductId)]
          this.getImageProduct(uniqueProductId)
        } else {
          this.listProductRetur = []
        }
        this.loading = false
      })
    },
    fetchDetailDistributor() {
      const warehouseId = this.$route.params.warehouse_id
      if (warehouseId) {
        this.loading = true
        this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
          id: [warehouseId],
        })
          .then(({ data }) => {
            if (data?.length === 1) {
              this.detailDistributor = data[0]
            }
            this.loading = false
          })
          .catch((err) => {
            this.$notification.error({
              message: err.response.statusText,
            })
            this.loading = false
          })
      }
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    onClickDeleteAction(returItemId, catalogTitle) {
      this.showModalDelete = true
      this.selectedDeleteData = {
        returItemId: returItemId,
        catalogTitle: catalogTitle,
      }
    },
    onClickDetailAction(productDetail) {
      this.showModalDetailProduct = true
      this.propsIsEdit = false
      this.productDetail = productDetail
    },
    onClickEditAction(productDetail) {
      this.showModalDetailProduct = true
      this.propsIsEdit = true
      this.productDetail = productDetail
    },
    onDeleteReturItemChecked() {
      this.checkedList.map((item) => {
        this.onDeleteReturItem(item)
      })
      if (this.checkedList == this.listProductRetur) {
        this.$router.push({ path: '/account/retur' })
      }
    },
    onDeleteReturItem(returitemId) {
      this.loading = true
      if (this.listProductRetur.length === 1) {
        this.$store.dispatch('retur/ONDELETERETUR', {
            channel_id: this.$store.state.user.user_data.channel_id,
            return_id: this.returId,
          })
            .then(() => {
              this.$router.push({ path: '/account/retur' })
            })
      } else {
        if (returitemId) {
          this.$store.dispatch('retur/ONDELETERETURITEM', {
            channel_id: this.$store.state.user.user_data.channel_id,
            return_item_id: returitemId,
          })
            .then(({ data }) => {
              this.loading = false
              this.showModalDelete = false
              this.$notification.success({
                message: 'Berhasil menghapus produk',
                duration: 3,
              })
              this.getDraftRetur('DRAFT')
            })
            .catch((err) => {
              this.loading = false
              this.$notification.error({
                message: 'Failed delete item',
              })
            })
        } else {
          this.loading = false
          this.$notification.error({
            message: 'Failed delete item, cant get retur item',
          })
        }
      }
    },
    emitShowModalAddProduct(val) {
      this.showModalAddProduct = val
    },
    emitSubmitModalAddProduct(val) {
      if (val && val?.length > 0) {
        const selectedReturnNumber = val[0].data?.return_number ?? null
        if (selectedReturnNumber) {
          this.getDraftReturItems(selectedReturnNumber)
        } else {
          this.$notification.error({
            message: 'retur number not found after add product',
          })
        }
      }
    },
    emitShowModalRequestRetur(val) {
      this.showModalRequestRetur = val
    },
    emitSubmitModalRequestRetur(val) {
      if (data?.status.toUpperCase() === 'REQUESTED') {
        this.$router.push({ path: '/account/retur' })
      }
    },
    emitShowModalDetailProduct(val) {
      this.showModalDetailProduct= val
    },
    emitSubmitModalDetailProduct(val) {
      if (val?.length > 0) {
        const selectedReturnNumber = val[0]?.return_number ?? null
        if (selectedReturnNumber) {
          this.getDraftReturItems(selectedReturnNumber)
        } else {
          this.$notification.error({
            message: 'retur number not found after update qty product',
          })
        }
      }
    },
    getImageProduct(arrProductId) {
      const promises = []
      this.loading = true
      arrProductId.map((obj) => {
        promises.push(
          this.$store.dispatch('product/GETPRODUCTDETAIL', {
            product_id: obj,
            channel_id: this.$store.state.user.user_data.channel_id,
          })
        )
      })
      Promise.all(promises)
        .then((data) => {
          if (data?.length > 0) {
            const listProductDetail =  data?.map((obj, index) => {
              return {
                img_url: obj.showimg_url,
                product_id: arrProductId[index]
              }
            })
            this.listProductRetur = this.listProductRetur?.map((obj) => {
              return {
                ...obj,
                img_url: listProductDetail?.find((item) => item.product_id === obj.product_id)?.img_url
              }
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.$notification.error({
            message: 'failure get image detail product',
          })
          this.loading = false
        })
    },
    getBaseQtyProduct(arrCatalogId, whsId) {
      this.loading = true
      this.$store.dispatch('product/GETBASEQTYUOM', {
        channel_id: this.$store.state.user.user_data.channel_id,
        warehouse_id: whsId,
        catalog_id: arrCatalogId.join(','),
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.listProductRetur = this.listProductRetur?.map((obj) => {
              return {
                ...obj,
                base_qty: data?.find((item) => item.catalog_id === obj.catalog_id)
              }
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.$notification.error({
            message: 'failure get base qty product',
          })
          this.loading = false
        })
    },
    getQtyBasedOnUOM(item) {
      const _qtyVal = item?.requested_item_qty
      const _uomVal = item?.item_uom
      if (item?.base_qty) {
        const selectedUOM = item?.base_qty?.units?.find((obj) => obj.unit.toUpperCase() === _uomVal.toUpperCase())
        if (selectedUOM) {
          return `${_qtyVal / selectedUOM.numerator * selectedUOM.denumerator} ${_uomVal}`
        } else {
          return null
        }
      } else {
        return '-'
      }
    },
  },
  mounted() {
    this.getDraftRetur('DRAFT')
  },
  destroyed() {
  },
}
</script>

<style lang="scss">
.active {
  background: #E8F6FE;
  border: 1px solid var(--biz-brand-button) !important;
  border-radius: 12px;
  color: var(--biz-brand-button);
}

.inactive {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  color: #929292;
}

.customBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.status:hover {
  cursor: auto !important;
}

.thumbnail {
  cursor: pointer;
}

.container-button-type {
  overflow-x: hidden;
  overflow: hidden;;
}

.action-button {
  border-radius: 8px;
  padding: 5px 40px;
}

.status-tag {
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 20px;
}

.desc-empty-state {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--biz-brand-button);
}
.sub-title {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.warehouse-name {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.desc-empty-filter {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}
.table-header {
  background-color: #F4F4F4;
  color: #767676;
  th {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 10px 10px 0px;
  }
  th:first-child{
    border-radius: 6px 0 0 0;
  }
  th:last-child{
    border-radius: 0 6px 0 0;
  }
}
.table-body {
  td {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
  }
}
div.modal .modal-dialog.modal-400 {
  width: 400px !important;
}
.modal-desc-container {
  p {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }

  b {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
}
.checked-all {
  color: gray;
  font-size: 15px;
}
.delete-btn {
  line-height: 35px;
  font-weight: 600;
  color: #ff0000;
  cursor: pointer;
  width: 100px;
}
.action-return-create {
  gap: 15px;

}
</style>
