<template>
  <b-modal id="modal-add-product" v-model="showModal" centered class="p-3" hide-footer :hide-header="true" @hide="onCloseModal">
    <template v-if="!loading">
      <div v-if="detailReferenceOrder">
        <div slot="modal-header" class="d-flex align-items-center justify-content-between py-2 w-100">
          <span class="text-header-modal mb-0">Tambah Produk</span>
          <span @click="onCloseModal" class="mb-0" style="cursor: pointer;">
            <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2246 5.0175L15.7423 12.499L23.2246 19.9805C24.2553 21.0125 24.2553 22.6877 23.2273 23.7226C22.1939 24.76 20.5209 24.7574 19.4875 23.7253L12 16.2385L4.51247 23.7253C3.47909 24.7574 1.80609 24.76 0.772705 23.7226C-0.258011 22.6879 -0.258013 21.0127 0.775369 19.9805L8.25769 12.499L0.775369 5.0175C-0.258013 3.98543 -0.258011 2.31023 0.772705 1.27536C1.28813 0.756718 1.96732 0.5 2.64398 0.5C3.32065 0.5 3.99717 0.759386 4.5126 1.27282L12.0001 8.75964L19.4876 1.27282C20.0031 0.756716 20.6796 0.5 21.3562 0.5C22.0329 0.5 22.7121 0.759386 23.2275 1.27536C24.258 2.31023 24.258 3.98543 23.2246 5.0175Z" fill="var(--biz-brand-button)"/>
            </svg>
          </span>
        </div>
        <div class="mb-3 mt-3">
          <div class="w-100 mb-3 container-order-number p-3 d-flex align-items-center justify-content-between">
            <div>
              <div class="text-order-number mb-1">{{ detailReferenceOrder && detailReferenceOrder.order_number }}</div>
              <div class="text-order-detail d-flex align-items-center" style="gap: 0px 5px;">
                <div>{{ getDateReferenceOrder }}</div>
                <svg width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 3C1.10218 3 0.720645 2.84196 0.43934 2.56066C0.158036 2.27936 0 1.89782 0 1.5C0 1.10218 0.158036 0.720645 0.43934 0.43934C0.720645 0.158036 1.10218 0 1.5 0C1.89782 0 2.27936 0.158036 2.56066 0.43934C2.84196 0.720645 3 1.10218 3 1.5C3 1.89782 2.84196 2.27936 2.56066 2.56066C2.27936 2.84196 1.89782 3 1.5 3Z" fill="black"/>
                </svg>
                <div>{{ getQtyReferenceOrder }}</div>
              </div>
            </div>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.5C5.37273 0.5 0 5.87273 0 12.5C0 19.1273 5.37273 24.5 12 24.5C18.6273 24.5 24 19.1273 24 12.5C24 5.87273 18.6273 0.5 12 0.5ZM17.2015 10.4709C17.2972 10.3614 17.3701 10.2339 17.4159 10.0958C17.4617 9.95774 17.4794 9.81191 17.4679 9.66689C17.4565 9.52188 17.4162 9.38062 17.3494 9.25141C17.2825 9.12221 17.1905 9.00768 17.0788 8.91455C16.967 8.82143 16.8378 8.7516 16.6987 8.70916C16.5595 8.66672 16.4133 8.65254 16.2686 8.66745C16.1239 8.68236 15.9836 8.72605 15.8561 8.79596C15.7285 8.86588 15.6162 8.96059 15.5258 9.07454L10.8349 14.7025L8.40764 12.2742C8.20189 12.0755 7.92632 11.9655 7.64029 11.968C7.35426 11.9705 7.08064 12.0852 6.87838 12.2875C6.67612 12.4897 6.56139 12.7633 6.5589 13.0494C6.55642 13.3354 6.66637 13.611 6.86509 13.8167L10.1378 17.0895C10.245 17.1966 10.3733 17.2802 10.5146 17.335C10.6559 17.3898 10.8071 17.4145 10.9585 17.4076C11.1099 17.4007 11.2582 17.3624 11.3939 17.295C11.5297 17.2277 11.6499 17.1328 11.7469 17.0164L17.2015 10.4709Z" fill="#2CD397"/>
            </svg>
          </div>
          <div class="w-100 mb-3 d-flex flex-column" style="gap: 10px 0px;">
            <div class="container-retur-detail d-flex align-items-center justify-content-between">
              <div class="text-title w-25">Nama Produk</div>
              <div class="text-value w-75 text-right">{{ getProductNameReferenceOrder }}</div>
            </div>
            <div class="container-retur-detail d-flex align-items-center justify-content-between">
              <div class="text-title w-25">Qty</div>
              <div class="text-value w-75 text-right">{{ `${qtyValue} ${selectedUOMValue}` }}</div>
            </div>
            <div class="container-retur-detail d-flex align-items-center justify-content-between">
              <div class="text-title w-25">Alasan Retur</div>
              <div class="text-value w-75 text-right">{{ getReasonReferenceOrder }}</div>
            </div>
          </div>
        </div>
        <a-row :gutter="[16, 16]">
          <a-col :xs="24" :sm="24" :md="12">
            <a-button
              class="mt-3"
              style="
                width: 100%;
                color: var(--biz-brand-button) !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;
              "
              @click.prevent="() => detailReferenceOrder = null"
            >
              Batal
            </a-button>
          </a-col>
          <a-col :xs="24" :sm="24" :md="12">
            <a-button
              @click.prevent="() => onAddCreateRetur()"
              :disabled="isLoadingAdd"
              type="primary"
              block
              class="mt-3"
              :style="
                `width: 100%;
                background: ${isLoadingAdd ? '#ceeaff' : 'var(--biz-brand-button)'} !important;
                color: #FFFFFF !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;`
              "
            >
              <div v-if="isLoadingAdd"><a-spin></a-spin></div>
              <div v-else>Tambah Produk</div>
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div v-else>
        <div slot="modal-header" class="d-flex align-items-center justify-content-between py-2 w-100">
          <span class="text-header-modal mb-0">Tambah Produk</span>
          <span @click="onCloseModal" class="mb-0" style="cursor: pointer;">
            <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2246 5.0175L15.7423 12.499L23.2246 19.9805C24.2553 21.0125 24.2553 22.6877 23.2273 23.7226C22.1939 24.76 20.5209 24.7574 19.4875 23.7253L12 16.2385L4.51247 23.7253C3.47909 24.7574 1.80609 24.76 0.772705 23.7226C-0.258011 22.6879 -0.258013 21.0127 0.775369 19.9805L8.25769 12.499L0.775369 5.0175C-0.258013 3.98543 -0.258011 2.31023 0.772705 1.27536C1.28813 0.756718 1.96732 0.5 2.64398 0.5C3.32065 0.5 3.99717 0.759386 4.5126 1.27282L12.0001 8.75964L19.4876 1.27282C20.0031 0.756716 20.6796 0.5 21.3562 0.5C22.0329 0.5 22.7121 0.759386 23.2275 1.27536C24.258 2.31023 24.258 3.98543 23.2246 5.0175Z" fill="var(--biz-brand-button)"/>
            </svg>
          </span>
        </div>
        <div class="mb-3 mt-3">
          <div class="w-100 mb-3">
            <div class="label-field mb-2">Nama Produk</div>
            <a-select
              class="w-100"
              show-search
              placeholder="Cari Nama Produk"
              v-model="searchProductValue"
              @change="handleChangeSearch"
              :options="listSearchProduct"
              @search="fetchProduct"
              :filter-option="false"
              :not-found-content="!onFetchingProduct ? searchProductValue.length > 0 ? 'Produk tidak ditemukan' : '' : undefined"
            >
              <template #suffixIcon>
                <span class="fe fe-search"></span>
              </template>
              <template v-if="onFetchingProduct" #notFoundContent>
                <a-spin size="small" />
              </template>
            </a-select>
          </div>
          <div class="w-100 mb-3">
            <div class="label-field mb-2">Qty</div>
            <a-input placeholder="Masukkan Qty" v-model="qtyValue" @change="handleChangeQty"  type="number" v-on:keypress="numbersOnly" @copy.prevent @paste.prevent>
              <template #addonAfter>
                <a-select
                  style="width: 80px"
                  v-model="selectedUOMValue"
                  :options="listUOM"
                >
                </a-select>
              </template>
            </a-input>
          </div>
          <div class="w-100 mb-3">
            <div class="label-field mb-2">Alasan Retur</div>
            <a-select
              class="w-100"
              v-model="selectedReasonValue"
              @change="handleChangeReason"
              :options="listReason"
            >
            </a-select>
          </div>
        </div>
        <div class="w-100">
          <a-button
            @click.prevent="() => onCheckReferenceOrder()"
            type="primary"
            block
            class="mt-3 modal-button-submit"
            style="
              border-radius: 12px !important;
            "
            :disabled="!selectedReasonValue || !selectedUOMValue || !searchProductValue || !qtyValue || +qtyValue === 0"
          >
            Cek No. Referensi Order
          </a-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <BigLoader class="rotate-img mb-4"/>
        <div class="loader-text-title mb-3">Cek Referensi No. Order</div>
        <div class="loader-text-desc">Kino Siap sedang memproses referensi nomor order yang Anda cari</div>
      </div>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import BigLoader from '@/components/Icons/BigLoader.vue'

export default {
  name: 'modal-add-product',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
    sellerName: {
      type: String,
      default: () => '',
    },
    sellerId: {
      type: String,
      default: () => '',
    },
    warehouseId: {
      type: String,
      default: () => null,
    }
  },
  components: {
    BigLoader,
  },
  data: function () {
    return {
      loading: false,
      searchProductValue: '',
      selectedProductId: '',
      qtyValue: '',
      selectedUOMValue: '',
      selectedReasonValue: '',
      onFetchingProduct: false,
      listSearchProduct: [],
      timeoutSearch: 0,
      listUOM: [],
      listReason: [
        {
          label: 'Produk cacat atau rusak',
          value: 'damaged_product',
        },
        {
          label: 'Produk mendekati expired',
          value: 'expired_product',
        },
        {
          label: 'Lain-lain',
          value: 'other_reason',
        },
      ],
      getListBaseUOM: [],
      detailReferenceOrder: null,
      baseUOM: '',
      isLoadingAdd: false,
    }
  },
  computed: {
    getDateReferenceOrder() {
      return this.detailReferenceOrder ? moment(this.detailReferenceOrder.created_at).format('DD MMM YYYY') : '-'
    },
    getQtyReferenceOrder() {
      if (this.detailReferenceOrder?.items?.length > 0) {
        const detailItem = this.detailReferenceOrder?.items[0]
        return `${detailItem?.qty} ${detailItem?.uom}`
      } else {
        return '-'
      }
    },
    getProductNameReferenceOrder() {
      const findSelectedproduct = this.listSearchProduct.find((obj) => obj.value === this.searchProductValue)
      return findSelectedproduct ? findSelectedproduct.label : '-'
    },
    getReasonReferenceOrder() {
      const findSelectedReason = this.listReason.find((obj) => obj.value === this.selectedReasonValue)
      return findSelectedReason ? findSelectedReason.label : '-'
    },
  },
  methods: {
    onCloseModal() {
      this.$emit('emitShowModal', false)
      this.searchProductValue = ''
      this.listSearchProduct = []
      this.onFetchingProduct = false
      this.listUOM = []
      this.selectedUOMValue = ''
      this.selectedReasonValue = ''
      this.qtyValue = ''
      this.detailReferenceOrder = null
      this.selectedProductId = ''
    },
    onCheckReferenceOrder() {
      this.loading = true
      this.$store.dispatch('retur/GETELIGIBLEORDER', {
        channel_id: this.$store.state.user.user_data.channel_id,
        seller_id: this.sellerId,
        catalog_id: this.searchProductValue,
        base_qty: this.covertToBaseQtyUOM(this.qtyValue, this.selectedUOMValue),
        // uom: this.selectedUOMValue,
      })
      .then(({ data }) => {
        this.loading = false
        if (data) {
          this.detailReferenceOrder = data
          this.$notification.success({
            message: 'Data Produk Berhasil ditemukan',
            duration: 3,
          })
        } else {
          this.$notification.error({
            message: 'Referensi no order tidak ditemukan',
            duration: 3,
          })
        }
      })
      .catch(err => {
        this.loading = false
        this.$notification.error({
          message: 'error',
          description: err.response?.data?.message || err.message || 'Internval Server Error',
        })
      })
    },
    covertToBaseQtyUOM(qtyValue, uomValue) {
      const selectedUOM = this.getListBaseUOM.find((obj) => obj.unit.toUpperCase() === uomValue.toUpperCase())
      if (selectedUOM) {
        return qtyValue * selectedUOM.numerator / selectedUOM.denumerator
      } else {
        return null
      }
    },
    handleChangeSearch(val) {
      this.searchProductValue = val
      this.selectedProductId = this.listSearchProduct.find((obj) => obj.value === val).productId
      this.listUOM = []
      this.selectedUOMValue = ''
      this.selectedReasonValue = ''
      this.qtyValue = ''
      this.getBaseQtyUOM(val)
    },
    getBaseQtyUOM(catalogId) {
      this.$store.dispatch('product/GETBASEQTYUOM', {
        channel_id: this.$store.state.user.user_data.channel_id,
        warehouse_id: this.warehouseId || this.$route.params.warehouse_id,
        catalog_id: catalogId,
      })
      .then(({ data }) => {
        if (data.length > 0) {
          const selectedData = data[0]
          this.getListBaseUOM = selectedData && selectedData.units.length > 0 ? selectedData.units: []
          let createListUOM = []
          selectedData?.units?.map((obj, index) => {
            if (obj.numerator === 1 && obj.denumerator === 1) {
              this.baseUOM = obj.unit.toUpperCase()
              this.selectedUOMValue = obj.unit.toUpperCase()
            }
            createListUOM.push({
              label: obj.unit.toUpperCase(),
              value: obj.unit.toUpperCase(),
            })
          })
          this.listUOM = createListUOM
        }
      })
    },
    handleChangeQty(val) {
      if (val.target.value > 1000000000) {
        this.qtyValue = 1000000000
      } else {
        this.qtyValue = val.target.value
      }
    },
    handleChangeReason(val) {
      this.selectedReasonValue = val
    },
    fetchProduct(val) {
      this.searchProductValue = val
      if (this.timeoutSearch) clearTimeout(this.timeoutSearch)
      this.timeoutSearch = setTimeout(() => {
        if (val.length >= 3) {
          this.listSearchProduct = []
          this.onFetchingProduct = true
          const warehouseId = this.warehouseId || this.$route.params.warehouse_id
          this.$store.dispatch('product/GETPRODUCTLIST', {
            channel_id: this.$store.state.user.user_data.channel_id,
            limit: 50,
            warehouse_query: `warehouse_id=${warehouseId}`,
            search_query: val,
          })
          .then(({ data }) => {
            let createList = []
            
            if (data !== null) {
              createList = data.map((obj) => {
                return {
                  label: obj?.title,
                  value: obj?.catalogs[0]?.id,
                  productId: obj?.id,
                }
              })
            }
            this.listSearchProduct = createList
            this.onFetchingProduct = false
          })
        }
      }, 1000)
    },
    onAddCreateRetur() {
      if (this.detailReferenceOrder?.items?.length > 0) {
        const detailItem = this.detailReferenceOrder?.items[0]
        const body = [{
          buyer_id: this.$store.state.user.user_data.buyer_id,
          buyer_name: `${this.$store.state.user.user_data.first_name} ${this.$store.state.user.user_data.last_name}`,
          seller_id: this.sellerId,
          seller_name: this.sellerName,
          // total_sku: 1,
          // notes_header: 'NOTES-ITEM',
          request_reason: this.selectedReasonValue,
          order_id: this.detailReferenceOrder?.id,
          order_number: this.detailReferenceOrder?.order_number,
          product_id: this.selectedProductId,
          catalog_id: detailItem.catalog_id,
          catalog_sku: detailItem.sku,
          catalog_title: detailItem.title,
          requested_item_qty: +this.qtyValue,
          item_uom: this.selectedUOMValue,
          currency: 'IDR',
        }]
        this.isLoadingAdd = true
        this.$store.dispatch('retur/ONSUBMITCREATERETUR', {
          channel_id: this.$store.state.user.user_data.channel_id,
          price_area_id: this.$store.state.user.user_data.price_area_id,
          customer_category_id: this.$store.state.user.user_data.customer_category_id,
          payload: body,
        })
        .then(({ data }) => {
          this.isLoadingAdd = false
          if (data?.success?.length > 0) {
            this.$emit('emitSubmitModal', data?.success)
            this.onCloseModal()
          } else {
            this.$notification.error({
              message: 'Gagal menambahkan product',
              description: 'Coba ulangi beberapa saat lagi atau hubungi Customer Service',
            })
          }
        })
        .catch((err) => {
          this.isLoadingAdd = false
          this.$notification.error({
            message: 'Gagal menambahkan product',
            description: 'Coba ulangi beberapa saat lagi atau hubungi Customer Service',
          })
        })
      } else {
        this.isLoadingAdd = false
        this.$notification.error({
          message: 'No items selected',
          description: err.response?.data?.message || err.message || 'Internval Server Error',
        })
      }
    },
    numbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();;
      } else {
        return true;
      }
    }
  },
  mounted() {
  },
  destroyed() {
  },
}
</script>

<style lang="scss">
  .modal-button-submit {
    width: 100%;
    background: var(--biz-brand-button) !important;
    color: #FFFFFF !important;
    border: 1px solid var(--biz-brand-button) !important;
    border-radius: 12px !important;
  }
  .text-header-modal {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .label-field {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
  }
  .rotate-img {
    animation: rotation 1s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .loader-text-desc {
    //styleName: Body - KINO/MD/Regular;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
  .loader-text-title {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .container-order-number {
    background-color: #EAFFF7;
    border-left: 3px solid #2CD397;
    .text-order-number {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    .text-order-detail {
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
  }
  .container-retur-detail {
    .text-title {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    .text-value {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #767676;
    }
  }
  .ant-select-dropdown-menu-item-disabled {
    text-align: center;
  }
</style>
