<template>
  <b-modal id="modal-request-retur" v-model="showModal" centered class="p-3" hide-footer :hide-header="true" size="400" @hide="onCloseModal">
    <template v-if="!loading">
      <div class="pt-3">
        <div class="container-title d-flex flex-column align-items-center justify-content-center mb-4" style="gap: 10px 0px;">
          <RequestReturQuestionMark/>
          <p class="title-text" v-if="isCancel">Batalkan</p>
          <p class="title-text" v-else>Kembali</p>
          <p class="desc-text" v-if="isCancel">Keluar dari halaman ini data Draft akan terhapus, anda yakin ? </p>
          <p class="desc-text" v-else>Keluar dari halaman ini akan tersimpan sebagai Draft, anda yakin ? </p>
        </div>
        <a-row :gutter="[16, 16]">
          <a-col :xs="24" :sm="24" :md="12">
            <a-button
              class="mt-3"
              style="
                width: 100%;
                color: var(--biz-brand-button) !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;
              "
              @click.prevent="() => onCloseModal()"
            >
              Batal
            </a-button>
          </a-col>
          <a-col :xs="24" :sm="24" :md="12">
            <a-button
              @click.prevent="() => onSubmitModal()"
              type="primary"
              block
              class="mt-3"
              style="
                width: 100%;
                background: var(--biz-brand-button) !important;
                color: #FFFFFF !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;
              "
            >
              Ya
            </a-button>
          </a-col>
        </a-row>
      </div>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import BigLoader from '@/components/Icons/BigLoader.vue'
import RequestReturQuestionMark from '@/components/Icons/RequestReturQuestionMark.vue'

export default {
  name: 'modal-request-retur',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
    isCancel: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    BigLoader,
    RequestReturQuestionMark,
  },
  data: function () {
    return {
      loading: false,
      potensialPointActive: null,
    }
  },
  computed: {
    getReturPotentialKinoCoin() {
      return this.toCurrency(Math.ceil(this.totalAmountReturRequested * (this.potensialPointActive ?? 0)))
    },
    getCurrentKinoCoin() {
      const kinoCoinDetails = this.$store.state.loyalty.totalKinoCoin.available_point
      return this.toCurrency(kinoCoinDetails)
    },
    subtractionKinoCoin() {
      const kinoCoinDetails = this.$store.state.loyalty.totalKinoCoin.available_point
      return this.toCurrency(kinoCoinDetails - Math.ceil(this.totalAmountReturRequested * (this.potensialPointActive ?? 0)))
    }
  },
  methods: {
    onCloseModal() {
      this.$emit('emitShowModal', false)
    },
    onSubmitModal() {
      this.$emit('emitSubmitModal', true)
    }
  },
}
</script>

<style lang="scss" scoped>
  div.modal .modal-dialog.modal-400 {
    width: 400px !important;
  }
  .rotate-img {
    animation: rotation 1s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .loader-text-desc {
    //styleName: Body - KINO/MD/Regular;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
  .loader-text-title {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .container-potential-coin {
    background-color: #FFF2F2;
    border-left: 3px solid #FF0000;
    p {
      margin: 0px;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    b {
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #015CA1;
    }
  }
  .container-title {
    p.title-text {
      margin: 0px;
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    p.desc-text {
      margin: 0px;
      color: #000000;
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      b {
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #015CA1;
      }
    }
  }
</style>
