<template>
  <b-modal id="modal-request-retur" v-model="showModal" centered class="p-3" hide-footer :hide-header="true" size="400" @hide="onCloseModal">
    <template v-if="!loading">
      <div class="pt-3">
        <div class="container-title d-flex flex-column align-items-center justify-content-center mb-4" style="gap: 10px 0px;">
          <RequestReturQuestionMark/>
          <p class="title-text">Ajukan Retur</p>
          <p class="desc-text">Anda yakin ingin mengajukan retur untuk <b>{{ productCount }} Produk</b> ini?</p>
        </div>
        <div class="w-100 mb-3 container-potential-coin px-2 py-3 d-flex align-items-center">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7.5 10.375C7.5 10.4438 7.44375 10.5 7.375 10.5H6.625C6.55625 10.5 6.5 10.4438 6.5 10.375V6.125C6.5 6.05625 6.55625 6 6.625 6H7.375C7.44375 6 7.5 6.05625 7.5 6.125V10.375ZM7 5C6.80374 4.99599 6.61687 4.91522 6.47948 4.775C6.3421 4.63478 6.26515 4.4463 6.26515 4.25C6.26515 4.0537 6.3421 3.86522 6.47948 3.725C6.61687 3.58478 6.80374 3.50401 7 3.5C7.19626 3.50401 7.38313 3.58478 7.52052 3.725C7.6579 3.86522 7.73485 4.0537 7.73485 4.25C7.73485 4.4463 7.6579 4.63478 7.52052 4.775C7.38313 4.91522 7.19626 4.99599 7 5Z" fill="#FF0000"/>
          </svg>
          <div class="ml-2 d-flex flex-column" style="gap: 3px 0px;">
            <p>Retur ini berpotensi mengurangi <b>{{ getReturPotentialKinoCoin }} Koin</b></p>
            <p>Jumlah koin Anda saat ini adalah <b>{{ getCurrentKinoCoin }} Koin</b></p>
            <p>Potensi koin mu setelah retur: <b>{{ subtractionKinoCoin }} Koin</b></p>
          </div>
        </div>
        <a-row :gutter="[16, 16]">
          <a-col :xs="24" :sm="24" :md="12">
            <a-button
              class="mt-3"
              style="
                width: 100%;
                color: var(--biz-brand-button) !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;
              "
              @click.prevent="() => onCloseModal()"
            >
              Batal
            </a-button>
          </a-col>
          <a-col :xs="24" :sm="24" :md="12">
            <a-button
              @click.prevent="() => onSubmitRequestRetur()"
              type="primary"
              block
              class="mt-3"
              style="
                width: 100%;
                background: var(--biz-brand-button) !important;
                color: #FFFFFF !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;
              "
            >
              Ya, Ajukan
            </a-button>
          </a-col>
        </a-row>
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <BigLoader class="rotate-img mb-4"/>
        <div class="loader-text-title mb-3">Proses Pengajuan Retur</div>
        <div class="loader-text-desc">Kino Siap sedang memproses permohonan pengajuan retur Anda</div>
      </div>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import BigLoader from '@/components/Icons/BigLoader.vue'
import RequestReturQuestionMark from '@/components/Icons/RequestReturQuestionMark.vue'

export default {
  name: 'modal-request-retur',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
    sellerName: {
      type: String,
      default: () => '',
    },
    sellerId: {
      type: String,
      default: () => '',
    },
    totalAmountReturRequested: {
      type: Number,
      default: () => 0,
    },
    productCount: {
      type: Number,
      default: () => 0,
    },
    returId: {
      type: String,
      default: () => '',
    },
  },
  components: {
    BigLoader,
    RequestReturQuestionMark,
  },
  data: function () {
    return {
      loading: false,
      potensialPointActive: null,
    }
  },
  computed: {
    getReturPotentialKinoCoin() {
      return this.toCurrency(Math.round(this.totalAmountReturRequested * (this.potensialPointActive ?? 0)))
    },
    getCurrentKinoCoin() {
      const kinoCoinDetails = this.$store.state.loyalty.totalKinoCoin.available_point
      return this.toCurrency(kinoCoinDetails)
    },
    subtractionKinoCoin() {
      const kinoCoinDetails = this.$store.state.loyalty.totalKinoCoin.available_point
      return this.toCurrency(kinoCoinDetails - Math.round(this.totalAmountReturRequested * (this.potensialPointActive ?? 0)))
    }
  },
  methods: {
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getLoyaltyActive() {
      this.$store.dispatch('loyalty/GETLOYALTYACTIVE', {})
        .then(({ data }) => {
          this.potensialPointActive = data.value
        })
        .catch((err) => {
          console.log(err)
          this.potensialPointActive = null
        })
        .finally(() => {
          this.loading = false
          
        })
    },
    onCloseModal() {
      this.$emit('emitShowModal', false)
    },
    onSubmitRequestRetur() {
      this.loading = true
      this.$store.dispatch('retur/ONSUBMITREQUESTRETUR', {
        channel_id: this.$store.state.user.user_data.channel_id,
        price_area_id: this.$store.state.user.user_data.price_area_id,
        customer_category_id: this.$store.state.user.user_data.customer_category_id,
        retur_id: this.returId,
      })
        .then(({ data }) => {
          this.loading = false
          if (data?.status.toUpperCase() === 'REQUESTED') {
            this.$emit('emitSubmitModal', data)
            this.onCloseModal()
            this.$notification.success({
              message: 'Pengajuan Retur Berhasil',
              duration: 3,
            })
            this.$router.push(`/account/retur/detail/${data?.return_number}`)
          } else {
            this.$notification.error({
              message: 'Gagal memproses permohonan pengajuan retur Anda',
              description: err.response?.data?.message || err.message || 'Internval Server Error',
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
  },
  mounted() {
    this.getLoyaltyActive()
  },
  destroyed() {
  },
}
</script>

<style lang="scss">
  div.modal .modal-dialog.modal-400 {
    width: 400px !important;
  }
  .rotate-img {
    animation: rotation 1s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .loader-text-desc {
    //styleName: Body - KINO/MD/Regular;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
  .loader-text-title {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .container-potential-coin {
    background-color: #FFF2F2;
    border-left: 3px solid #FF0000;
    p {
      margin: 0px;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    b {
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--biz-brand-button);
    }
  }
  .container-title {
    p.title-text {
      margin: 0px;
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    p.desc-text {
      margin: 0px;
      color: #000000;
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      b {
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: var(--biz-brand-button);
      }
    }
  }
</style>
